import { corpUserSettings } from "@telia/b2b-rest-client";

let getTooltipsPromise: Promise<corpUserSettings.TooltipSettings> | undefined;

export async function getTooltips(scopeId: string): Promise<string[] | undefined> {
  if (!getTooltipsPromise) {
    getTooltipsPromise = (async () =>
      corpUserSettings.PublicControllerService.getTooltips(scopeId))();
  }
  return (await getTooltipsPromise).settings;
}

export async function markTooltipAsSeen(scopeId: string, tooltipId: string): Promise<void> {
  await corpUserSettings.PublicControllerService.putTooltipSetting(scopeId, {
    tooltipId: tooltipId,
  });
  await _flush();
}

export async function _flush(): Promise<void> {
  await Promise.allSettled([getTooltipsPromise]);
  getTooltipsPromise = undefined;
}

export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}
